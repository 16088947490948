import { assembleUrl } from './assembleUrl.utils'

export const URLS = {
    cartage: {
        home: () => assembleUrl(`/`, {}),
        login: (args?: { email: string; loginToken: string }) =>
            assembleUrl('/user/login', args || {}),
        loginSuccess: (args?: { email?: string }) =>
            assembleUrl('/user/login/success', args || {}),
        order: (orderId: string) => assembleUrl(`/orders/${orderId}`, {}),
        customer: (customerId: string) =>
            assembleUrl(`/customers/${customerId}`, {}),
        shipperPortal: {
            shipments: () => assembleUrl('/sp/shipments', {}),
            shipment: (orderId: string) =>
                assembleUrl(`/sp/shipments/${orderId}`, {}),
        },
        superAdmin: {
            home: () => assembleUrl('/admin', {}),
        },
        tracking: (trackingToken: string) =>
            assembleUrl('/tracking', { trackingToken }),
    },
}
