import { Navigate, Route } from 'react-router-dom'
import { ShipperPortalRoute } from '../../routes/ShipperPortalRoute'
import { lazy } from 'react'

const MotherComponent = lazy(
    () =>
        import(
            /* webpackChunkName: 'MotherComponent' */ 'modules/MotherComponent'
        )
)
const ShipperPortalOrders = lazy(
    () =>
        import(
            /* webpackChunkName: 'ShipperPortalOrders' */ 'shipperPortal/modules/Orders'
        )
)
const OrderDetails = lazy(
    () =>
        import(
            /* webpackChunkName: 'OrderDetails' */ 'shipperPortal/modules/OrderDetails'
        )
)

export const ShipperPortalRoutes = () => {
    return (
        <Route path="sp" element={<ShipperPortalRoute />}>
            <Route element={<MotherComponent />}>
                <Route
                    index
                    element={
                        <Navigate
                            replace
                            to="/sp/shipments"
                            state={{ from: '/' }}
                        />
                    }
                />
                <Route path="shipments" element={<ShipperPortalOrders />} />
                <Route path="shipments/:id" element={<OrderDetails />} />
            </Route>
        </Route>
    )
}
