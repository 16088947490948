import styled from '@emotion/styled'
import { SPACING } from 'constants/spacing.constants'

export const Column = styled.div<{
    gap?: keyof typeof SPACING
    p?: keyof typeof SPACING
}>`
    display: flex;
    flex-direction: column;
    ${({ gap }) => gap && `gap: ${SPACING[gap]}`};
    ${({ p }) => p && `padding: ${SPACING[p]}`};
`
